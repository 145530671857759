.checkout {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex: 1;
    &_form {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
        &_group {
            margin: 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 300px;
            text-align: center;
            label {
                font-size: 20px;
                margin-bottom: 5px;
            }
            input,
            textarea {
                width: 100%;
                padding: 8px 16px;
                font-size: 16px;
                border: 1px solid var(--orange);
                border-radius: 8px;
            }
        }
    }
    &_basket {
        width: 300px;
    }
}
