.order {
    &_ul {
        display: inline-flex;
        flex-direction: column;
        max-height: 100vh;
        min-width: 30%;
        min-height: 30vw;
        overflow: auto;
    }
    &_li {
        display: flex;
        flex-direction: column;
        border: 1px solid;
        margin: 10px;
        padding: 10px;

        & > span {
            margin: 5px 0;
        }
    }
    &_basket {
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        border-top: 1px solid;
        border-bottom: 1px solid;
        & > span {
            margin: 5px 0;
        }
    }
    &_actions {
        button:first-child {
            margin-right: 10px;
        }
        margin-top: 10px;
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}
