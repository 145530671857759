* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

ul,
ol {
  list-style: none;
}

input,
button,
textarea,
select {
  font: inherit;
  border: none;
  outline: none;
}

button {
  cursor: pointer;
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
button {
  font-family: "Roboto", sans-serif;
  color: var(--primary);
}
h1 {
  font-size: 56px;
  font-weight: 500;
  line-height: 84px;
}
h2 {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
}
h3 {
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
}
h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
p {
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  letter-spacing: 0.01em;
}
p.small {
  font-size: 14px;
  font-weight: 300;
  line-height: 18.2px;
  letter-spacing: 0.01em;
}
:root {
  --white: #fff;
  --primary: #242424;
  --primary-hover: #474747;
  --primary-focus: #1f1f1f;
  --primary-disabled: #cfcfcf;
  --secondary-hover: #ebebeb;
  --border: var(--primary);
  --light-border: #585858;
  --placeholder: #8c8c8c;
  --orange: #ee6e26;
  --orange-hover: #fe6812;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 1300px) {
  .container {
    width: 900px;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    width: 750px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    margin: auto;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
}