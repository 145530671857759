.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
        height: 100px;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
            height: 100%;
            object-fit: cover;
        }
        h1 {
            font-size: 26px;
            margin-left: 10px;
            color: var(--primary);
        }
    }
    .navs {
        display: flex;
        align-self: center;
        button:not(:last-child) {
            margin-right: 20px;
        }
    }
    .navs_mobile {
        display: none;
        flex: 1;
        justify-content: flex-end;
    }
}

.burger {
    position: relative;
    width: 35px;
    height: 24px;
    margin: 0 20px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &.active .burgerLines {
        background-color: transparent;
    }

    &.active .burgerLines::before {
        transform: rotate(45deg);
        top: 0;
    }

    &.active .burgerLines::after {
        transform: rotate(-45deg);
        top: 0;
    }
}
.burgerNavs {
    flex-direction: column;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 10;
    right: -100%;
    width: 300px;
    top: 0;
    height: 100vh;
    transition: 0.3s;
    background-color: #fff;
    padding-bottom: 150px;
    & > button {
        margin-bottom: 20px;
        font-size: 22px;
    }
    &.active {
        right: 0;
    }
}
.navs_bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 2;
    background: rgba(0, 0, 0, 0.311);
}
.burgerLines {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: var(--orange);
    transition: background-color 0.3s;
    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: var(--orange);
        transition:
            transform 0.3s,
            top 0.3s,
            background-color 0.3s;
    }
    &::before {
        top: -10px;
        left: 0;
    }

    &::after {
        top: 10px;
        left: 0;
    }
}

@media screen and (max-width: 1000px) {
    .header {
        position: sticky;
        z-index: 10;
        top: 0;
        background-color: #fff;
        box-shadow: 0 -20px 20px 20px #fff;
        .logo {
            padding: 10px 0;
            height: 80px;
            h1 {
                font-size: 20px;
                line-height: 22px;
            }
        }
        .navs {
            display: none;
        }
        .navs_mobile {
            display: flex;
        }
    }
}

@media screen and (max-width: 400px) {
    .header {
        .logo {
            h1 {
                display: none;
            }
        }
    }
}
