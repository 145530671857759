.foodForm {
    &_form {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_group {
            max-width: 300px;
            margin: 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 100%;
            input {
                width: 100%;
                padding: 8px 16px;
                font-size: 14px;
                border: 1px solid var(--orange);
                border-radius: 8px;
            }
        }
    }
}
