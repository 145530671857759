.services {
    display: grid;
    justify-content: center;
    justify-items: center;
    grid-template-columns: repeat(3, minmax(0px, 300px));
    gap: 30px;
    padding-top: 100px;
    padding-bottom: 20px;

    &_el {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px 15px;
        border-radius: 10px;
        border: 1px solid var(--orange);
        box-shadow: 0 0 10px -5px var(--orange);
        text-align: center;
        img {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: contain;
            object-position: center;
        }
        h4 {
            font-weight: 600;
            margin: 20px 0 10px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .services {
        h4 {
            font-size: 20px;
            line-height: 22px;
        }
    }
}

@media screen and (max-width: 600px) {
    .services {
        grid-template-columns: repeat(2, 1fr);
        &_el {
            &:last-child {
                display: none;
            }
        }
    }
}
