.menu {
    padding-top: 100px;
    padding-bottom: 100px;
    h3 {
        text-align: center;
        margin-bottom: 30px;
    }
    &_list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
    }
}
@media screen and (max-width: 850px) {
    .menu {
        padding-top: 80px;
        padding-bottom: 20px;
        &_list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media screen and (max-width: 550px) {
    .menu {
        &_list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
