.terms {
    h2,
    h3,
    h4,
    h5,
    p {
        margin: 0;
    }
    h2 {
        font-size: 40px;
        margin-top: 30px;
        margin-bottom: 20px;
        line-height: 43px;
        font-weight: 500;
    }
    h3 {
        font-size: 28px;
        line-height: 32px;
        font-weight: 500;
        margin: 25px 0 0;
    }
    h4 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
        margin: 25px 0 15px 10px;
    }
    h5 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        margin: 25px 0 5px;
        text-decoration: underline;
    }
    p {
        font-size: 16px;
        line-height: 20px;
        &:last-child {
            margin-bottom: 30px;
        }
    }
}

@media (max-width: 500px) {
    .terms {
        h2 {
            font-size: 36px;
            margin-top: 26px;
            margin-bottom: 16px;
            line-height: 39px;
        }
        h3 {
            font-size: 24px;
            line-height: 28px;
            margin: 21px 0 0;
        }
        h4 {
            font-size: 20px;
            line-height: 24px;
            margin: 21px 0 11px 6px;
        }
        h5 {
            font-size: 16px;
            line-height: 20px;
            margin: 21px 0 1px;
        }
        p {
            font-size: 12px;
            line-height: 16px;
        }
        p:last-child {
            margin-bottom: 26px;
        }
    }
}
