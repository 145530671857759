.basketBtn {
    position: fixed;
    background-color: var(--orange);
    padding: 10px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 30px;
    right: 80px;
    border: 3px solid #fff;

    .count {
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--orange);
        color: white;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.checkout {
    z-index: 1;
    position: fixed;
    cursor: default;
    right: 100px;
    overflow: hidden;
    top: 100px;
    width: 400px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid transparent;
    box-shadow: 0 0 10px -5px transparent;
    height: 0;
    padding: 0px;
    transition: 0.3s;
    &.opened {
        background-color: white;
        border: 1px solid var(--orange);
        box-shadow: 0 0 10px -5px var(--orange);
        height: auto;
        padding: 20px;
    }
    &_item {
        margin-bottom: 10px;
    }
    &_total {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .closeBtn {
        width: 100%;
        text-align: right;
        background-color: transparent;
    }
}

.bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.115);
    display: none;
}

@media screen and (max-width: 1000px) {
    .basketBtn {
        position: relative;
        top: auto;
        right: auto;
    }
    .checkout {
        z-index: 1;
        position: fixed;
        cursor: default;
        right: 10px;
        overflow: hidden;
        top: 90px;
        width: calc(100vw - 20px);
        display: flex;
        flex-direction: column;
        background-color: transparent;
        border-radius: 10px;
        border: 1px solid transparent;
        box-shadow: 0 0 10px -5px transparent;
        height: 0;
        padding: 0px;
        transition: 0.3s;
        &.opened {
            background-color: white;
            border: 1px solid var(--orange);
            box-shadow: 0 0 10px -5px var(--orange);
            height: auto;
            padding: 20px;
        }
        &_item {
            margin-bottom: 10px;
        }
        &_total {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .closeBtn {
            width: 100%;
            text-align: right;
            background-color: transparent;
        }
    }
}
