.top {
    height: 100vh;
}

.video {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: 100;
    &_close {
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.533);
        left: 0;
        top: 0;
        z-index: 100;
    }
    iframe {
        position: absolute;
        width: 80%;
        max-width: 600px;
        aspect-ratio: 16/9;
        margin: auto;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
}

@media screen and (max-width: 500px) {
    .video {
        iframe {
            width: 90%;
        }
    }
}
