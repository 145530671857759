.food {
    &_ul {
        display: inline-flex;
        flex-direction: column;
        overflow: auto;
    }
    &_li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid;
        padding: 10px;
        margin-bottom: 10px;
        span {
            margin: 0 10px;
        }
        button:first-child {
            margin-right: 10px;
        }
    }
}
