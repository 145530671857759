.welcome {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_left {
        flex: 1;
        h4 {
            span {
                color: var(--orange);
                font-weight: 600;
                font-size: 35px;
            }
        }
        p {
            margin: 15px 0 25px;
            max-width: 500px;
            span {
                color: var(--orange);
                font-weight: 600;
            }
        }
        .actions {
            display: flex;
            align-items: center;
            .sep {
                height: 30px;
                width: 2px;
                margin: 0 20px;
                background-color: var(--primary);
                display: inline-block;
            }
        }
    }
    &_right {
        width: 30%;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        img {
            width: 100%;
        }
    }
}




@media screen and (max-width: 800px) {
    .welcome {
        padding-top: 80px;
        padding-bottom: 20px;
        &_left {
            margin-right: 15px;
            h4 {
                margin-bottom: 20px;
                font-size: 22px;
                span {
                    color: var(--orange);
                    font-weight: 600;
                    font-size: 26px;
                }
            }
            p {
                display: none;
            }
            .actions {
                .sep {
                    height: 30px;
                    width: 2px;
                    margin: 0 20px;
                    background-color: var(--primary);
                    display: inline-block;
                }
            }
        }
        &_right {
            width: 25%;
            margin-left: 15px;
        }
    }
}

@media screen and (max-width: 600px) {
    .welcome {
        padding-bottom: 20px;
        &_left {
            margin: 0;
            h4 {
                font-size: 24px;
                span {
                    font-size: 28px;
                }
            }
        }
        &_right {
            margin: 0;
            width: 0%;
        }
    }
}
