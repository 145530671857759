.btn {
    // margin-right: 20px;
    border-radius: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
}

.primary {
    background: var(--orange);
    color: #fff;

    &:hover {
        background: var(--orange-hover);
    }

    &:active {
        background: var(--orange);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &.disabled {
        background: var(--primary-disabled);
        color: #a7a7a7;
        cursor: not-allowed;
    }
}

.secondary {
    background: transparent;
    border: 1.5px solid var(--primary);
    color: black;

    &:hover {
        background: rgba(36, 36, 36, 0.1);
    }

    &:active {
        background: var(--secondary-hover);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &.disabled {
        border-color: var(--primary-disabled);
        color: #a7a7a7;
        cursor: not-allowed;
    }
}

.link {
    background: transparent;
    border: none;
    color: var(--primary-focus);
    padding: 0;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: -5px;
        height: 1px;
        width: 0%;
        left: 0;
        background-color: var(--primary-focus);
        transition: 0.3s;
    }

    &:hover::after {
        color: var(--primary-hover);
        width: 100%;
    }
}

.large:not(.link) {
    font-size: 18px;
    padding: 14px 24px;
}

.medium:not(.link) {
    font-size: 16px;
    padding: 12px 20px;
}

.small:not(.link) {
    font-size: 14px;
    padding: 10px 16px;
}

.icon {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .disabled & {
        border-color: #a7a7a7;
    }
}

.text {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}
