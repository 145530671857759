.maps {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .addr {
        display: flex;
        flex-direction: column;
        &_map {
            display: inline-flex;
            align-items: center;
            background-color: transparent;
            &.active {
                color: var(--orange);
            }
        }
        &_image {
            max-width: 50%;
            aspect-ratio: 16/10;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
}

.address {
    padding-top: 50px;
    padding-bottom: 30px;
}

@media screen and (max-width: 650px) {
    .maps {
        .addr {
            &_image {
                max-width: 60%;
                margin-left: 10px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .maps {
        flex-direction: column;
        .addr {
            &_image {
                max-width: 100%;
                margin: 0;
                margin-top: 10px;
            }
        }
    }
}
