.checkoutPage {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.checkoutInfo {
    flex: 1;
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}
